import { mapGetters } from "vuex";
import { mapState } from "pinia";
import { usePageStore } from "~/pinia/page";

if (import.meta.client && !window.graph) window.graph = {};

export default (options = {}) => ({
	data() {
		return {
			autofollow: options.autofollow || false, // if true, then routing is automatically triggered when there is a valid nextAction
			ignoreLayouts: options.ignoreLayouts || [],
			disableAutoInitGraph: options.disableAutoInitGraph || false,
			progressUpdated: false,
			loadingFlowGraph: false,
			// browsingMode: false,
		};
	},
	async mounted() {
		const pageStore = usePageStore();
		if (this.disableAutoInitGraph || pageStore.page?.metadata?.disableFlowFetch || this.$config.public.isPreview)
			return;

		try {
			await this.initGraph();
			if (import.meta.server) {
				this.validateGraph(); // Validate that we are allowed to be on this step
			}
		} catch (err) {
			this.$store.commit("flow/setError", err);
		}

		setTimeout(() => {
			this.progressUpdated = true;
			this.$store.commit("flow/setPreviousProgress", this.progress);
		}, 400);
	},
	watch: {
		nextAction(newValue) {
			if (newValue && newValue !== useRoute().path) {
				if (!this.currentStepOptional && this.autofollow /*&& useRoute()?.query?.prev !== 'true' */) {
					this.$router.push(this.nextAction);
					// browse mode?
					// i.e. when you navigate back, you are no longer in autofollow state
					// when you hit a step when nextAction is invalid, browsing mode is over

					// other option
					// make state noe longer valid for future points
				}
			}
		},
	},
	computed: {
		...mapState(usePageStore, ["page"]),
		...mapGetters("flow", [
			"flowGraph",
			"error",
			"loadingFlow",
			"progress",
			"previousProgress",
			"newCurrentPathProgress",
			"newCurrentPath",
			"nextAction",
			"currentStepOptional",
			"prevAction",
			"currentNode",
			"endNode",
			"isLastStep",
			"isFirstStep",
		]),
		getProgress() {
			if (!this.progressUpdated) return this.previousProgress;
			else return this.progress;
		},
	},
	methods: {
		async initGraph() {
			if (this.ignoreLayouts.includes(this.$nuxt?.$data?.layoutName)) return;
			try {
				await this.$store.dispatch("flow/fetchData", {
					force: true,
					pageId: this.page.id,
				});
			} catch (error) {
				console.error(error);
			}
		},
		validateGraph() {
			let lastValidRoute = this.newCurrentPathProgress?.[this.newCurrentPathProgress?.length - 1];
			if (lastValidRoute !== useRoute().path) {
				this.$nuxt?.context?.redirect(lastValidRoute);
			}
		},
		goBack() {
			if (this.prevAction) {
				this.$router.push(this.prevAction);
			} else {
				this.$router.push("/privat");
			}
		},
	},
	unmounted() {
		if (import.meta.client) {
			window.graph.beforeNext = null;
			window.graph.beforePrev = null;
		}
	},
});
